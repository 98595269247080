export const PlatfromPrioritasIcon = ({ width = 64, height = 65 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 64 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62.3162 48.9338C62.3162 49.3604 61.8743 49.7033 61.3257 49.7033H55.7104C56.4571 49.6652 57.0514 49.1928 57.0514 48.5985V48.1642H61.3257C61.8743 48.1642 62.3162 48.5071 62.3162 48.9338Z"
        fill="#091A2B"
      />
      <path
        d="M18.0571 17.4671L21.4095 17.49"
        stroke="#EFB003"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.2209 12.3242L22.5829 14.709"
        stroke="#EFB003"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3943 10.2138L25.379 13.5662"
        stroke="#EFB003"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.2019 46.5643C28.1676 48.5986 26.4609 49.7033 24.48 49.7033H6.8952C7.72568 49.7033 10.3238 48.85 12.6247 46.5643H30.2019Z"
        fill="#CECECE"
      />
      <path
        d="M2.66666 46.5643H12.6171C10.3314 48.85 7.71809 49.7033 6.89523 49.7033H2.66666C2.03428 49.7033 1.5238 49.1928 1.5238 48.5605V47.7071C1.5238 47.0747 2.03428 46.5643 2.66666 46.5643Z"
        fill="#EAEAEA"
      />
      <path
        d="M34.6286 49.7033H24.4876C26.4609 49.7033 28.1676 48.5986 30.2095 46.5643H34.6362C34.0038 46.5643 33.4933 47.0747 33.4933 47.7071V48.5605C33.4933 49.1928 34.0038 49.7033 34.6362 49.7033H34.6286Z"
        fill="#A7A8A8"
      />
      <path
        d="M22.1409 46.5643H14.0038V47.57H22.1409V46.5643Z"
        fill="#A7A8A8"
      />
      <path
        d="M56.0457 46.5643H34.6286C33.9974 46.5643 33.4857 47.0759 33.4857 47.7071V48.5605C33.4857 49.1916 33.9974 49.7033 34.6286 49.7033H56.0457C56.6769 49.7033 57.1886 49.1916 57.1886 48.5605V47.7071C57.1886 47.0759 56.6769 46.5643 56.0457 46.5643Z"
        fill="#7C7C7C"
      />
      <path
        d="M56.3809 47.7452H53.8362V48.4462H56.3809V47.7452Z"
        fill="#020A11"
      />
      <path
        d="M52.8762 47.7452H51.0324C50.8388 47.7452 50.6819 47.9021 50.6819 48.0957C50.6819 48.2892 50.8388 48.4462 51.0324 48.4462H52.8762C53.0697 48.4462 53.2266 48.2892 53.2266 48.0957C53.2266 47.9021 53.0697 47.7452 52.8762 47.7452Z"
        fill="#020A11"
      />
      <path
        d="M49.7447 47.7909H45.7295C45.5359 47.7909 45.379 47.9478 45.379 48.1414C45.379 48.335 45.5359 48.4919 45.7295 48.4919H49.7447C49.9383 48.4919 50.0952 48.335 50.0952 48.1414C50.0952 47.9478 49.9383 47.7909 49.7447 47.7909Z"
        fill="#020A11"
      />
      <path
        d="M61.12 16.1109C61.12 16.1109 61.1581 16.1109 61.1734 16.1186C61.1353 16.1109 61.0895 16.1109 61.0515 16.1109H61.1124H61.12Z"
        fill="#CECECE"
      />
      <path
        d="M62.4305 17.6423L61.1353 25.8785L61.0286 26.5262L58.0038 45.6347C57.9124 46.1681 57.4476 46.5643 56.9143 46.5643H56.4267C56.7086 46.5643 56.9448 46.3585 56.9981 46.0843L59.8248 28.2023L61.6305 16.7738C61.6762 16.469 61.4705 16.1871 61.181 16.1262C61.9657 16.149 62.56 16.8652 62.4305 17.65V17.6423Z"
        fill="#CECECE"
      />
      <path
        d="M61.0438 26.4652L59.4057 36.7814L58.0038 45.6347C57.9124 46.1681 57.4476 46.5643 56.9143 46.5643H56.4267C56.7086 46.5643 56.9448 46.3585 56.9981 46.0843L58.3238 37.749L59.8324 28.2024C60.0457 28.0804 60.7543 27.6157 61.0362 26.5262C61.0438 26.5109 61.0438 26.4881 61.0515 26.4728L61.0438 26.4652Z"
        fill="#A7A8A8"
      />
      <path
        d="M40.6705 16.8652L22.7352 34.8004L24.3886 24.3166L31.8476 16.8652H40.6705Z"
        fill="#458CFF"
      />
      <path
        d="M58.7657 28.8195L57.2953 38.1071L56.16 45.3224C56.1143 45.6043 55.8705 45.8024 55.5886 45.8024H41.7753L58.7581 28.8195H58.7657Z"
        fill="#0E43C1"
      />
      <path
        d="M60.5486 17.5205L58.7581 28.8195L41.7752 45.8024H21.6686C21.3181 45.8024 21.0514 45.49 21.0972 45.1471L22.7352 34.8005L40.6781 16.8576H60C60.3505 16.8576 60.6171 17.17 60.5638 17.5128L60.5486 17.5205Z"
        fill="#2560E0"
      />
      <path
        d="M31.8476 16.8652L24.3962 24.3166L25.5009 17.3452C25.539 17.0633 25.7828 16.8652 26.0647 16.8652H31.8552H31.8476Z"
        fill="#6BBDF7"
      />
      <path
        d="M57.2953 38.1147L56.16 45.33C56.1143 45.6119 55.8705 45.81 55.5886 45.81H49.6L57.2953 38.1147Z"
        fill="#071A8C"
      />
      <path
        d="M61.0591 16.1109H25.2343C24.9448 16.1109 24.7162 16.3166 24.6629 16.5909L20.0305 45.909C19.9772 46.2519 20.2438 46.5643 20.5943 46.5643H56.4267C56.7086 46.5643 56.9448 46.3585 56.9981 46.0843L61.6305 16.7662C61.6838 16.4233 61.4172 16.1109 61.0591 16.1109ZM58.7657 28.8271L57.2952 38.1147L56.16 45.33C56.1143 45.6119 55.8705 45.81 55.5886 45.81H21.6686C21.3181 45.81 21.0514 45.4976 21.0972 45.1547L22.7353 34.8081L24.3962 24.3243L25.501 17.3528C25.5391 17.0709 25.7829 16.8728 26.0648 16.8728H39.3753L39.3372 17.1319C39.2914 17.4443 39.5276 17.7262 39.8476 17.7262H45.3333C45.5924 17.7262 45.8057 17.5433 45.8438 17.2919L45.9124 16.8728H60C60.3505 16.8728 60.6172 17.1852 60.5638 17.5281L58.7733 28.8271H58.7657Z"
        fill="#020A11"
      />
      <path
        d="M59.4057 36.7814L58.0038 45.6347C57.9124 46.1681 57.4476 46.5643 56.9143 46.5643H56.4267C56.7086 46.5643 56.9448 46.3585 56.9981 46.0843L58.3238 37.749L58.3315 37.7566C58.3315 37.7566 59.2457 37.1319 59.3981 36.8043C59.4057 36.7966 59.4057 36.789 59.4134 36.789L59.4057 36.7814Z"
        fill="#7C7C7C"
      />
      <path
        d="M45.7949 33.2619C45.6223 33.4241 45.5334 33.6647 45.5648 33.9054L46.3704 40.0938C46.4541 40.6953 45.8159 41.1191 45.298 40.8209L39.9466 37.6979C39.7425 37.5776 39.4914 37.5724 39.2717 37.677L33.7058 40.371C33.4181 40.5123 33.1095 40.4338 32.9107 40.2455L46.4541 27.1624L50.0217 27.832C50.6076 27.9419 50.8116 28.6847 50.3722 29.0875L45.7897 33.2672L45.7949 33.2619Z"
        fill="#EFB003"
      />
      <path
        d="M46.4594 27.1631L32.916 40.2461C32.7434 40.0839 32.644 39.8328 32.6963 39.566L32.8428 38.8703L33.9727 33.4561C34.025 33.2207 33.957 32.9748 33.7896 32.8022L29.5472 28.2773C29.1287 27.8379 29.3955 27.116 29.9866 27.0532L36.1279 26.3941C36.3633 26.3732 36.5673 26.2267 36.6824 26.0122L39.6275 20.53C39.91 19.9964 40.6738 20.0278 40.9196 20.5823L43.4358 26.279C43.53 26.4987 43.7287 26.6556 43.9641 26.6975L44.7331 26.844L46.4646 27.1683L46.4594 27.1631Z"
        fill="#EFB003"
      />
      <path
        d="M38.682 28.8705C38.7064 28.2152 38.3423 27.6696 37.8689 27.652C37.3954 27.6344 36.9918 28.1514 36.9674 28.8068C36.943 29.4621 37.3071 30.0077 37.7806 30.0253C38.2541 30.0429 38.6577 29.5259 38.682 28.8705Z"
        fill="#050000"
      />
      <path
        d="M42.935 29.0424C42.9594 28.387 42.5953 27.8415 42.1218 27.8239C41.6484 27.8063 41.2448 28.3233 41.2204 28.9786C41.196 29.634 41.5601 30.1796 42.0336 30.1972C42.5071 30.2148 42.9107 29.6978 42.935 29.0424Z"
        fill="#050000"
      />
      <path
        d="M39.7718 34.5544C39.6515 34.5544 39.5312 34.5492 39.4108 34.5387C37.559 34.3713 36.3925 32.9642 36.3402 32.9066C36.1675 32.6921 36.1937 32.3783 36.4082 32.2057C36.6174 32.033 36.9261 32.0592 37.0987 32.2737C37.1144 32.2894 38.0717 33.4245 39.505 33.55C40.4466 33.6337 41.4144 33.2623 42.3664 32.4463C42.5757 32.2684 42.8843 32.2946 43.0622 32.5038C43.24 32.7131 43.2139 33.0269 43.0046 33.2048C41.9532 34.1045 40.8651 34.5597 39.7666 34.5597L39.7718 34.5544Z"
        fill="#050000"
      />
      <path
        d="M31.4613 23.3896C31.4613 23.33 31.4613 23.2737 31.4414 23.2174C31.3255 21.1213 29.2294 21.0518 28.9446 21.0518C29.7758 21.0683 30.4281 21.714 30.3652 22.4723L30.0738 26.4029L31.1665 26.8466"
        fill="#03317A"
      />
      <path
        d="M29.9818 41.6951L28.9619 41.2812L28.6606 45.3144C28.6109 46.0065 27.9851 46.5462 27.2234 46.5561C29.6606 46.5429 29.7666 44.5859 29.7666 44.2813C29.7666 44.268 29.7732 44.2548 29.7765 44.2448L29.9818 41.6984V41.6951Z"
        fill="#010A33"
      />
      <path
        d="M31.1678 26.8492L31.138 27.3922L29.9824 41.6939L28.9625 41.28L30.0718 26.4055H30.0751L31.1678 26.8492Z"
        fill="#00216B"
      />
      <path
        d="M30.0743 26.4062L28.6637 45.3105C28.6074 46.0092 27.9782 46.549 27.21 46.549H15.5144C14.6667 46.549 14.0011 45.8967 14.0574 45.1251L14.3654 41.0555L14.3885 41.0323L15.0773 40.3899L14.8786 43.0688L14.7495 44.7874C14.7263 45.1119 15.0078 45.3867 15.3621 45.3867H27.4153C27.7431 45.3867 28.008 45.1582 28.0279 44.8635L28.1637 43.0688L28.2465 41.9429L29.3624 27.0684L29.4849 26.9558V26.9492L30.0776 26.3995L30.0743 26.4062Z"
        fill="#003370"
      />
      <path
        d="M28.9621 41.2875L28.6607 45.314C28.6541 45.4001 28.6376 45.4862 28.6144 45.5657C28.5945 45.6319 28.5713 45.6948 28.5415 45.7544C28.5217 45.7942 28.5018 45.8372 28.4753 45.8703C28.4621 45.8968 28.4455 45.92 28.4289 45.9465C28.3892 46.0028 28.3462 46.0525 28.2998 46.1021C28.1508 46.2545 27.9687 46.3737 27.7601 46.4531C27.7203 46.4664 27.6806 46.4829 27.6342 46.4962C27.5481 46.5194 27.4554 46.5359 27.3627 46.5459C27.3263 46.5459 27.2865 46.5525 27.2501 46.5525C27.2402 46.5525 27.2269 46.5525 27.2203 46.5525H23.3096L28.9588 41.2841L28.9621 41.2875Z"
        fill="#012456"
      />
      <path
        d="M30.3681 22.4756L30.0733 26.4062L29.3614 27.0684L29.6793 22.8134C29.7058 22.4889 29.421 22.214 29.0667 22.214H17.0068C16.6856 22.214 16.4141 22.4425 16.3942 22.7339L16.2088 25.2108L16.1691 25.777L16.1028 26.6413L15.7883 30.8566L15.5929 33.4891L15.0763 40.3966L14.3611 41.0621L14.9737 32.85L15.1161 30.9261L15.4505 26.4459L15.4936 25.8764L15.7585 22.2935C15.8114 21.5948 16.4439 21.0518 17.2121 21.0518H28.9111C29.7555 21.0518 30.4244 21.7041 30.3647 22.4723L30.3681 22.4756Z"
        fill="#094C89"
      />
      <path
        d="M21.234 21.0551L15.4524 26.4459L15.4954 25.8764L15.7637 22.2935C15.8166 21.5948 16.4491 21.0518 17.2173 21.0518H21.234V21.0551Z"
        fill="#176E94"
      />
      <path
        d="M29.3578 27.0749L28.2419 41.9527L28.1624 43.0753H14.874L15.0727 40.3964L29.3578 27.0749Z"
        fill="#2560E0"
      />
      <path
        d="M28.2426 41.9536L28.1631 43.0762H27.0372L28.2426 41.9536Z"
        fill="#0E43C1"
      />
      <path
        d="M29.6771 22.8128L29.3592 27.0711L15.0741 40.3959L16.1039 26.6407L16.1701 25.7764L19.9881 22.2134H29.0645C29.4188 22.2134 29.7036 22.4883 29.6771 22.8128Z"
        fill="#458CFF"
      />
      <path
        d="M28.1608 43.0746L28.0284 44.8694C28.0085 45.1641 27.7436 45.3926 27.4158 45.3926H15.3592C15.0049 45.3926 14.7235 45.1177 14.7466 44.7932L14.8758 43.0746H28.1608Z"
        fill="#BCD5FF"
      />
      <path
        d="M28.1596 43.0746L28.0271 44.8694C28.0072 45.1641 27.7423 45.3926 27.4145 45.3926H24.5535L27.037 43.0746H28.1596Z"
        fill="#7292CE"
      />
      <path
        d="M29.9818 41.6951L28.9619 41.2812L28.6606 45.3144C28.6109 46.0065 27.9851 46.5462 27.2234 46.5561C29.6606 46.5429 29.7666 44.5859 29.7666 44.2813C29.7666 44.268 29.7732 44.2548 29.7765 44.2448"
        fill="#010A33"
      />
      <path
        d="M22.3807 23.4862C22.6148 23.4862 22.8045 23.3024 22.8045 23.0756C22.8045 22.8488 22.6148 22.665 22.3807 22.665C22.1466 22.665 21.9568 22.8488 21.9568 23.0756C21.9568 23.3024 22.1466 23.4862 22.3807 23.4862Z"
        fill="#00295D"
      />
      <path
        d="M30.0741 26.4065L29.8986 28.741H29.8953L30.0708 26.4065H30.0741Z"
        fill="#003370"
      />
      <path
        d="M29.8986 28.741H29.8953L30.0708 26.4065H30.0741L29.8986 28.741Z"
        fill="#01155E"
      />
      <path
        d="M28.6043 45.599L28.5546 46.2844C28.2168 46.4467 27.7798 46.5527 27.2234 46.5527C27.8758 46.5427 28.4255 46.1487 28.6043 45.599Z"
        fill="#00125E"
      />
      <path
        d="M28.1615 43.0757L28.0291 44.8704C28.0092 45.1651 27.7443 45.3936 27.4165 45.3936H24.5555L27.039 43.0757H28.1615Z"
        fill="#7292CE"
      />
      <path
        d="M19.9927 22.2147L16.1714 25.7777L16.2111 25.2115L16.3966 22.7346C16.4164 22.4432 16.6846 22.2114 17.0092 22.2114H19.9894L19.9927 22.2147Z"
        fill="#6BBDF7"
      />
      <path
        d="M25.0549 31.0923L16.1672 39.3772C16.0447 39.2746 15.9785 39.1123 16.0149 38.9368L16.6176 36.1487L16.8527 35.0692C16.8858 34.9202 16.8394 34.7646 16.7302 34.6553L15.5911 33.4864L15.7864 30.8539L16.1705 30.8141L18.26 30.5956C18.3726 30.5857 18.4785 30.5327 18.5514 30.4532C18.5514 30.4532 18.5514 30.4532 18.558 30.4532C18.5845 30.4234 18.6044 30.3936 18.6242 30.3605L19.2832 29.1717L20.5481 26.8869C20.6872 26.6386 20.9952 26.5823 21.2104 26.7147C21.2866 26.7578 21.3528 26.8273 21.3958 26.92L23.0449 30.5261C23.0581 30.5592 23.078 30.5857 23.1045 30.6155C23.1045 30.6221 23.1144 30.6254 23.121 30.6353C23.1343 30.6519 23.1541 30.6684 23.174 30.6883C23.1873 30.6982 23.1939 30.7016 23.2071 30.7115C23.2237 30.7214 23.2436 30.7347 23.2634 30.7446L23.2998 30.7612C23.333 30.7744 23.3628 30.7843 23.3992 30.791L25.0549 31.089V31.0923Z"
        fill="#458CFF"
      />
      <path
        d="M16.8529 35.0731L16.6178 36.1526L16.0152 38.9407C15.9787 39.1162 16.045 39.2785 16.1675 39.3811L15.0781 40.3977L15.5946 33.4903L16.7337 34.6592C16.8397 34.7684 16.8861 34.9241 16.8562 35.0731H16.8529Z"
        fill="#458CFF"
      />
      <path
        d="M17.0856 30.7506L16.906 33.1303L16.3492 32.5526L16.478 30.8134L17.0856 30.7506Z"
        fill="#003370"
      />
      <path
        d="M25.4607 30.9628L17.4266 38.4521C17.3158 38.3593 17.256 38.2126 17.2889 38.054L17.8337 35.5336L18.0462 34.5578C18.0761 34.4231 18.0342 34.2824 17.9355 34.1836L16.9058 33.127L17.0824 30.7473L17.4296 30.7114L19.3184 30.5138C19.4201 30.5049 19.5159 30.457 19.5818 30.3851C19.5818 30.3851 19.5818 30.3851 19.5878 30.3851C19.6117 30.3582 19.6297 30.3312 19.6476 30.3013L20.2433 29.2267L21.3868 27.1613C21.5125 26.9368 21.7909 26.8859 21.9854 27.0057C22.0543 27.0446 22.1141 27.1074 22.153 27.1913L23.6437 30.451C23.6557 30.4809 23.6737 30.5049 23.6976 30.5318C23.6976 30.5378 23.7066 30.5408 23.7126 30.5498C23.7245 30.5647 23.7425 30.5797 23.7605 30.5976C23.7724 30.6066 23.7784 30.6096 23.7904 30.6186C23.8054 30.6276 23.8233 30.6396 23.8413 30.6485L23.8742 30.6635C23.9041 30.6755 23.9311 30.6845 23.964 30.6904L25.4607 30.9598V30.9628Z"
        fill="#2560E0"
      />
      <path
        d="M25.0498 34.4557C24.948 34.5485 24.8941 34.6832 24.9121 34.8209L25.391 38.362C25.4359 38.7062 25.0617 38.9487 24.7534 38.7781L21.5835 36.9941C21.4608 36.9252 21.3111 36.9192 21.1854 36.9791L17.8838 38.5177C17.7161 38.5925 17.5365 38.5566 17.4168 38.4518L25.4509 30.9625L27.5522 31.3427C27.8994 31.4055 28.0251 31.8336 27.7587 32.0641L25.0438 34.4587L25.0498 34.4557Z"
        fill="#003370"
      />
      <path
        d="M24.6527 34.1834C24.5539 34.2761 24.503 34.4138 24.521 34.5515L24.982 38.0926C25.0298 38.4369 24.6647 38.6793 24.3683 38.5087L21.3062 36.7217C21.1894 36.6528 21.0457 36.6498 20.92 36.7097L17.7351 38.2513C17.5705 38.3321 17.3939 38.2872 17.2802 38.1794L25.0298 30.6931L27.0713 31.0763C27.4065 31.1391 27.5233 31.5642 27.2718 31.7947L24.6497 34.1863L24.6527 34.1834Z"
        fill="#D87E00"
      />
      <path
        d="M25.0319 30.6937L17.2822 38.18C17.1834 38.0872 17.1266 37.9435 17.1565 37.7909L17.2403 37.3928L17.8869 34.2947C17.9168 34.16 17.8779 34.0193 17.7821 33.9205L15.3545 31.3313C15.115 31.0799 15.2677 30.6668 15.6059 30.6309L19.1201 30.2537C19.2548 30.2417 19.3715 30.1579 19.4374 30.0352L21.1226 26.8982C21.2843 26.5929 21.7213 26.6109 21.862 26.9281L23.3018 30.1879C23.3556 30.3136 23.4694 30.4034 23.6041 30.4273L24.0441 30.5111L25.0349 30.6967L25.0319 30.6937Z"
        fill="#EFB003"
      />
      <path
        d="M20.5813 31.6718C20.5952 31.2968 20.3869 30.9846 20.116 30.9745C19.845 30.9644 19.6141 31.2603 19.6001 31.6353C19.5862 32.0103 19.7945 32.3225 20.0655 32.3325C20.3364 32.3426 20.5673 32.0468 20.5813 31.6718Z"
        fill="#050000"
      />
      <path
        d="M23.0135 31.7698C23.0275 31.3948 22.8191 31.0826 22.5482 31.0725C22.2773 31.0625 22.0463 31.3583 22.0324 31.7333C22.0184 32.1083 22.2268 32.4205 22.4977 32.4306C22.7686 32.4406 22.9996 32.1448 23.0135 31.7698Z"
        fill="#050000"
      />
      <path
        d="M21.2052 34.9244C21.1364 34.9244 21.0675 34.9214 20.9987 34.9154C19.939 34.8196 19.2715 34.0144 19.2416 33.9815C19.1428 33.8588 19.1578 33.6792 19.2805 33.5804C19.4002 33.4816 19.5768 33.4966 19.6756 33.6193C19.6846 33.6283 20.2324 34.2778 21.0526 34.3497C21.5913 34.3976 22.1451 34.185 22.6899 33.7181C22.8096 33.6163 22.9862 33.6313 23.088 33.751C23.1898 33.8708 23.1748 34.0503 23.0551 34.1521C22.4534 34.667 21.8308 34.9274 21.2022 34.9274L21.2052 34.9244Z"
        fill="#050000"
      />
      <path
        d="M21.6915 21.8093C21.5867 21.8033 21.479 21.8422 21.3981 21.9171C21.3143 21.9919 21.2664 22.0967 21.2634 22.2104L21.1317 25.6737C21.1228 25.9042 21.2994 26.0957 21.5239 26.1047H21.5448C21.7693 26.1047 21.9489 25.9281 21.9579 25.7066L22.0896 22.2433C22.0926 22.1326 22.0537 22.0248 21.9788 21.944C21.904 21.8632 21.8022 21.8153 21.6945 21.8123L21.6915 21.8093Z"
        fill="#EFB003"
      />
      <path
        d="M16.8241 33.7586C16.7104 33.7526 16.6086 33.7945 16.5278 33.8693L12.9717 37.1979C12.8071 37.3535 12.7951 37.6169 12.9478 37.7846C13.0256 37.8684 13.1364 37.9193 13.2501 37.9193C13.3549 37.9193 13.4537 37.8804 13.5315 37.8085L17.0875 34.4799C17.2552 34.3243 17.2641 34.0609 17.1115 33.8933C17.0367 33.8124 16.9349 33.7645 16.8241 33.7586Z"
        fill="#EFB003"
      />
      <path
        d="M29.1792 37.8333L25.8865 34.2353C25.8117 34.1545 25.7099 34.1066 25.5992 34.1006C25.4884 34.1006 25.3837 34.1366 25.3028 34.2114C25.1382 34.3671 25.1262 34.6305 25.2819 34.7981L28.5745 38.3961C28.6524 38.4799 28.7631 38.5308 28.8769 38.5308C28.9816 38.5308 29.0804 38.4918 29.1582 38.42C29.3229 38.2644 29.3348 38.0009 29.1792 37.8333Z"
        fill="#D87E00"
      />
      <path
        d="M28.1992 25.6518C28.1244 25.571 28.0226 25.5231 27.9118 25.5171C27.8011 25.5111 27.6963 25.553 27.6155 25.6278L24.0594 28.9564C23.8948 29.112 23.8828 29.3755 24.0355 29.5431C24.1133 29.6269 24.2241 29.6778 24.3378 29.6778C24.4426 29.6778 24.5414 29.6389 24.6192 29.567L28.1752 26.2385C28.3399 26.0828 28.3519 25.8194 28.1992 25.6518Z"
        fill="#EFB003"
      />
      <path
        d="M15.4881 25.1566C15.4133 25.0728 15.3115 25.0279 15.2007 25.0219C15.09 25.0159 14.9852 25.0578 14.9044 25.1326C14.7398 25.2883 14.7278 25.5517 14.8834 25.7193L18.1761 29.3173C18.2539 29.4011 18.3647 29.452 18.4784 29.452C18.5832 29.452 18.682 29.4131 18.7598 29.3413C18.9274 29.1856 18.9364 28.9222 18.7807 28.7546L15.4881 25.1566Z"
        fill="#EFB003"
      />
      <path
        d="M21.1092 37.1473C21.0045 37.1383 20.8997 37.1742 20.8159 37.249C20.7291 37.3239 20.6782 37.4346 20.6752 37.5484L20.5435 41.0116C20.5345 41.2421 20.7111 41.4337 20.9356 41.4427H20.9566C21.1781 41.4427 21.3607 41.2661 21.3696 41.0446L21.5013 37.5813C21.5103 37.3508 21.3337 37.1592 21.1092 37.1503V37.1473Z"
        fill="#D87E00"
      />
    </svg>
  )
}
